// @ts-check

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// State.
import { useAppDispatch } from 'core/store';
import { updateCampaignAsync } from 'core/store/slices';

import { cloneDeep } from 'lodash';

// Components.
import { SearchText } from './SearchText';

const Container = styled.div`
  .checkbox-container {
    border: 1px solid #ccc;
  }

  .export-list {
    max-height: calc(100vh - 410px - 20px);
    overflow-y: auto;
  }

  .btn-clear-all,
  .btn-select-all {
    background: none;
    border: none;
    cursor: pointer;
    color: #8c1d40;
    text-decoration: underline;
  }

  .filter-field-banner {
    background-color: #d0d0d0;
    border-color: #e8e8e8;
    font-weight: bolder;
  }
`;

const NestedContainer = styled.div`
  ul {
    list-style-type: none;
    padding-left: 20px;
  }
`;

const updateAncestors = (node) => {
  if (!node.parent) {
    return;
  }

  const parent = node.parent;
  if (parent.checked && !node.checked) {
    parent.checked = false;
    updateAncestors(parent);
    return;
  }

  if (!parent.checked && node.checked) {
    if (parent.childrenNodes.every((node) => node.checked)) {
      parent.checked = true;
      updateAncestors(parent);
      return;
    }
  }

  return;
};

const toggleDescendants = (node) => {
  const checked = node.checked;

  node.childrenNodes.forEach((node) => {
    node.checked = checked;
    toggleDescendants(node);
  });
};

const findNode = (nodes, label, ancestors) => {
  let node = undefined;
  if (ancestors.length === 0) {
    return nodes.filter((node) => node.label === label)[0];
  }

  for (let ancestor of ancestors) {
    const candidates = node ? node.childrenNodes : nodes;
    node = candidates.filter((node) => node.label === ancestor)[0];
  }
  return node?.childrenNodes.filter((node) => node.label === label)[0];
};

const collectCheckedNodes = (nodes) => {
  const checkedNodes = [];

  const traverseNodes = (nodes) => {
    nodes.forEach((node) => {
      if (
        node.checked &&
        // Do not include parent nodes (category names).
        // These are nodes that have children and are only used for grouping in the UI.
        (!node.childrenNodes || node.childrenNodes.length === 0)
      ) {
        checkedNodes.push(node.name);
      }
      if (node.childrenNodes) {
        traverseNodes(node.childrenNodes);
      }
    });
  };

  traverseNodes(nodes);
  return checkedNodes;
};

const countChildrenNodes = (nodes) => {
  let count = 0;
  const traverseNodes = (nodes) => {
    nodes.forEach((node) => {
      if (node.childrenNodes) {
        count += node.childrenNodes.length;
        traverseNodes(node.childrenNodes);
      }
    });
  };
  traverseNodes(nodes);
  return count;
};

// Based on this article:
// https://medium.com/sltc-sean-learns-to-code/how-i-build-a-nested-checkbox-react-component-7eef982d1ea9
const NestedCheckbox = ({
  campaign,
  data,
  toggleModal,
  onCancel,
  onExportList,
}) => {
  const initialNodes = data;
  const [nodes, setNodes] = useState(initialNodes);
  const [searchText, setSearchText] = useState('');
  const [filteredNodes, setFilteredNodes] = useState(initialNodes);
  const [displayNodes, setDisplayNodes] = useState(nodes);
  const [allSelected, setAllSelected] = useState(false);

  const dispatch = useAppDispatch();

  const toggleSelectAllNodes = (nodes, select) => {
    return nodes.map((node) => {
      const updatedNode = { ...node, checked: select };
      if (node.childrenNodes) {
        updatedNode.childrenNodes = toggleSelectAllNodes(
          node.childrenNodes,
          select,
        );
      }
      return updatedNode;
    });
  };

  const handleClearAll = () => {
    const updatedNodes = toggleSelectAllNodes(nodes, false);
    setNodes(updatedNodes);
    setAllSelected(false);
  };

  const handleSelectAll = () => {
    const updatedNodes = toggleSelectAllNodes(nodes, !allSelected);
    setNodes(updatedNodes);
    setAllSelected(!allSelected);
  };

  // nodes contains the full list of categories and fields.
  // filteredNodes contains the nodes that match the search text.
  useEffect(() => {
    const nodesChildrenCount = countChildrenNodes(nodes);
    const filteredNodesChildrenCount = countChildrenNodes(filteredNodes);

    // If no search text then filterNodes is the same as nodes.
    if (filteredNodesChildrenCount === nodesChildrenCount) {
      setDisplayNodes(nodes);
      // Else use filteredNodes which has been filtered by search text.
    } else if (filteredNodesChildrenCount < nodesChildrenCount) {
      setDisplayNodes(filteredNodes);
    }
  }, [filteredNodes, nodes]);

  const handleBoxChecked = (e, ancestors) => {
    const checked = e.currentTarget.checked;
    const node = findNode(nodes, e.currentTarget.value, ancestors);
    const filteredNode = findNode(
      filteredNodes,
      e.currentTarget.value,
      ancestors,
    );

    node.checked = checked;
    toggleDescendants(node);
    updateAncestors(node);
    filteredNode.checked = checked;
    toggleDescendants(filteredNode);
    updateAncestors(filteredNode);

    // Cloning the filteredNodes array before cloning nodes seems to prevent overwriting the nodes array.
    setNodes(cloneDeep(filteredNodes));
    setNodes(cloneDeep(nodes));
  };

  const handleExport = () => {
    const checkedNodes = collectCheckedNodes(nodes);

    const newCampaign = /** @type {CampaignModel} */ ({
      ...campaign,
    });

    newCampaign.exportFields = checkedNodes;

    //TODO: this hard-coded status is should be replaced with something more meaninful or removed.
    const campaignStatus = 'UPDATED_FIELDS_EXPORTED';

    // The reloadData flag repopulate the campaign details (e.g. name, descriptiopn, purposes, etc.) on the page.
    // Would otherwise be blank after export function completes.
    dispatch(
      updateCampaignAsync({
        status: campaignStatus,
        reloadData: true,
        campaign: {
          ...newCampaign,
        },
        // TODO:TBD resume this line show banner message when toggle set to true
        // successMessage: checked
        //   ? `Campaign ${field} has been shared. ` +
        //     `It can now be viewed and exported publicly.`
        //   : '',
      }),
    );

    onExportList();

    // Close the modal
    toggleModal();
  };

  const handleSearchChange = (event) => {
    const newSearchText = event.target.value.toLowerCase();
    setSearchText(newSearchText);

    // Filter the nodes based on the search text. Only store childrenNodes that match the search text.
    const filteredNodes = nodes
      .map((node) => {
        const filteredChildren = node.childrenNodes
          ? node.childrenNodes.filter((child) =>
              child.label.toLowerCase().includes(newSearchText),
            )
          : [];

        if (
          node.label.toLowerCase().includes(newSearchText) ||
          filteredChildren.length > 0
        ) {
          return {
            ...node,
            childrenNodes: filteredChildren,
          };
        }

        return null;
      })
      .filter((node) => node !== null);

    setFilteredNodes(filteredNodes);
  };

  return (
    <Container>
      <div className="d-flex justify-content-between mb-1 mt-1">
        <div>Select the fields to include in the export list.</div>
        <button
          type="button"
          className="btn-clear-all"
          onClick={handleClearAll}
        >
          Clear all
        </button>
        <button
          type="button"
          className="btn-select-all"
          onClick={handleSelectAll}
        >
          Select all
        </button>
      </div>
      <div className="filter-field-banner p-1">Filter field</div>
      <div className="checkbox-container">
        <SearchText searchText={searchText} onChange={handleSearchChange} />
        <div>
          <div className="export-list">
            <NestedCheckboxHelper
              nodes={displayNodes}
              ancestors={[]}
              onBoxChecked={handleBoxChecked}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <button
          type="button"
          className="btn btn-md btn-gray"
          aria-describedby="cancel-export-list"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="btn btn-md btn-maroon ml-3"
          type="button"
          aria-describedby="do-export-list"
          // disabled={collectCheckedNodes(nodes).length === 0}
          onClick={handleExport}
        >
          <i className="fas fa-file-export" />
          &nbsp; Export List
        </button>
      </div>
    </Container>
  );
};

const NestedCheckboxHelper = ({ nodes, ancestors, onBoxChecked }) => {
  const prefix = ancestors.join('.');
  return (
    <NestedContainer>
      <ul>
        {nodes.map(({ label, checked, childrenNodes }) => {
          const id = `${prefix}.${label}`;
          let children = null;
          if (childrenNodes.length > 0) {
            children = (
              <NestedCheckboxHelper
                key={id}
                nodes={childrenNodes}
                ancestors={[...ancestors, label]}
                onBoxChecked={onBoxChecked}
              />
            );
          }

          return (
            <li key={id}>
              <input
                type="checkbox"
                name={id}
                value={label}
                checked={checked}
                onChange={(e) => onBoxChecked(e, ancestors)}
              />
              <label htmlFor={id}>{label}</label>
              {children}
            </li>
          );
        })}
      </ul>
    </NestedContainer>
  );
};

export { NestedCheckbox };
